import React from 'react';

import {
  FaRegNewspaper,
  FaAdn,
  FaBehanceSquare,
  FaBluetooth,
  FaMusic,
  FaDribbbleSquare,
  FaEmpire,
  FaGetPocket,
  FaHouzz,
  FaItunesNote,
  FaPlaystation,
  FaArrowCircleUp,
  FaBabyCarriage,
  FaBriefcase,
  FaCocktail,
  FaLaugh,
} from 'react-icons/fa';

import classes from './styles.module.scss';

export default function Card({ style, id }) {
  const icons = [];
  const strings = [];
  const colors = [];

  icons.push(<FaRegNewspaper color="white" size={20} />);
  icons.push(<FaAdn color="white" size={20} />);
  icons.push(<FaBehanceSquare color="white" size={20} />);
  icons.push(<FaBluetooth color="white" size={20} />);
  icons.push(<FaMusic color="white" size={20} />);
  icons.push(<FaDribbbleSquare color="white" size={20} />);
  icons.push(<FaEmpire color="white" size={20} />);
  icons.push(<FaGetPocket color="white" size={20} />);
  icons.push(<FaHouzz color="white" size={20} />);
  icons.push(<FaItunesNote color="white" size={20} />);
  icons.push(<FaPlaystation color="white" size={20} />);
  icons.push(<FaArrowCircleUp color="white" size={20} />);
  icons.push(<FaBabyCarriage color="white" size={20} />);
  icons.push(<FaBriefcase color="white" size={20} />);
  icons.push(<FaCocktail color="white" size={20} />);
  icons.push(<FaLaugh color="white" size={20} />);

  strings.push('Population growth trend');
  strings.push('Crime rate');
  strings.push('Price per sq. ft');
  strings.push('Sold price');
  strings.push('Population age groups');
  strings.push('Education');
  strings.push('Economic activity rate');
  strings.push('Occupational grade');
  strings.push('Outstanding mortgages');
  strings.push('Average asking price');
  strings.push('Average yield');
  strings.push('New built ratio');
  strings.push('Asking price changes');
  strings.push('Average time to sell');
  strings.push('Time to rent');
  strings.push('Return-on-investment');

  colors.push('#B18D00');
  colors.push('#F76D1E');
  colors.push('#0843A6');
  colors.push('#9E0059');
  colors.push('#20870F');
  colors.push('#828AE9');
  colors.push('#BB2653');
  colors.push('#DCBEC7');
  colors.push('#DEF802');
  colors.push('#80FA96');
  colors.push('#D2CEFB');
  colors.push('#1DC885');
  colors.push('#07953A');
  colors.push('#F5FC00');
  colors.push('#BB5DC4');
  colors.push('#B22478');

  return (
    <div className={classes.Card} style={style} data-type="card">
      <div
        className={classes.iconContainer}
        style={{ backgroundColor: colors[id] }}
      >
        {icons[id]}
      </div>
      <span>{strings[id]}</span>
    </div>
  );
}
