/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';

import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility';

import Card from '../../components/Card';

import classes from './styles.module.scss';

export default function MainPage() {
  const [firstRowContent, setFirstRowContent] = useState([]);
  const [secondRowContent, setSecondRowContent] = useState([]);
  const [pageIsVisible, setPageIsVisible] = useState(true);

  const tickerRef = useRef();

  const handleVisibilityChange = (isVisible) => {
    setPageIsVisible(isVisible);
  };

  const setCardsOpacity = () => {
    setInterval(() => {
      const cards = document.querySelectorAll('div[data-type="card"]');
      cards.forEach((card) => {
        const leftPosition = card.getBoundingClientRect().left;
        switch (true) {
          case leftPosition < 0:
            card.style.opacity = window.innerWidth >= 600 ? '0.15' : '0.5';
            break;
          case leftPosition < 136 && window.innerWidth >= 600:
            card.style.opacity = '0.35';
            break;
          case leftPosition < 272 && window.innerWidth >= 900:
            card.style.opacity = '0.5';
            break;
          case leftPosition < 384 && window.innerWidth >= 1280:
            card.style.opacity = '0.65';
            break;
          case leftPosition + 112 > window.innerWidth:
            card.style.opacity = window.innerWidth >= 600 ? '0.15' : '0.5';
            break;
          case leftPosition + 112 > window.innerWidth - 136 &&
            window.innerWidth >= 600:
            card.style.opacity = '0.35';
            break;
          case leftPosition + 112 > window.innerWidth - 272 &&
            window.innerWidth >= 900:
            card.style.opacity = '0.5';
            break;
          case leftPosition + 112 > window.innerWidth - 384 &&
            window.innerWidth >= 1280:
            card.style.opacity = '0.65';
            break;
          default:
            card.style.opacity = '1';
        }
      });
    }, 250);
  };

  useEffect(() => {
    const content = [];
    const content2 = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 16; i++) {
      const firstKey = Math.random();
      const secondKey = Math.random();
      content.push(<Card key={firstKey} id={i} />);
      content2.push(<Card key={secondKey} id={i} />);
    }
    setFirstRowContent(content);
    setSecondRowContent(content2);
  }, []);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  useEffect(() => {
    if (secondRowContent.length && firstRowContent.length && pageIsVisible) {
      setTimeout(() => {
        setCardsOpacity();
      }, 200);
    }
  }, [firstRowContent, secondRowContent, pageIsVisible]);

  return (
    <div className={classes.MainPage}>
      <PageVisibility onChange={handleVisibilityChange}>
        {pageIsVisible && (
          <>
            <Ticker direction="toRight" speed={6}>
              {() => (
                <div style={{ display: 'flex', height: 144 }} ref={tickerRef}>
                  {firstRowContent}
                </div>
              )}
            </Ticker>
            <Ticker speed={3}>
              {() => (
                <div style={{ display: 'flex', height: 140 }} ref={tickerRef}>
                  {secondRowContent}
                </div>
              )}
            </Ticker>
          </>
        )}
      </PageVisibility>
    </div>
  );
}
